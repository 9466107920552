import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import IndustriesPage from '../components/IndustriesPage/IndustriesPage';
import { IndustryItemInterface } from '../interfaces/industries';
import { IndustriesDataExampleRespnse } from '../templateObjects/industriesObject';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { staticPageRedirection } from '../utils/staticPagesRedirection';
import { INDUSTRIES_LANGUAGES } from '../constants/sitesLanguagesGroup';
import { EN_US, ES_US } from '../constants/languageCode';
import { redirectToMainPage } from '../utils/redirectToMainPage';
import Alternate from '../components/Seo/Alternate';
import { prepareAlternateData } from '../utils/prepareAlternateData';

const Industries = ({
    data: {
        drupal: { terms },
    },
}) => {
    const { language } = useI18next();
    const languagesWithUrls = [];
    process.env.LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push(prepareAlternateData(language, 'industries'))
    );

    useEffect(() => {
        staticPageRedirection(language, 'industries', INDUSTRIES_LANGUAGES);
        (language === ES_US || language === EN_US) && redirectToMainPage(language);
    }, []);

    const industriesData: IndustryItemInterface[] = terms ?? IndustriesDataExampleRespnse;

    return (
        <>
            <Alternate languages={languagesWithUrls} />
            <IndustriesPage industriesData={industriesData} />
        </>
    );
};

export default Industries;

export const query = graphql`
    query ($language: String!) {
        drupal {
            terms(language: $language, vid: "branch", node_type: "branch") {
                connected_nodes {
                    title
                    url
                }
                description
                field_image {
                    alt
                    field_media_image
                }
                name
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
